@import '../shared/relicsAndSoulBreaks';

:local {
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  :global(.ag-root) :global(.ag-body-container) {
    :global(.ag-row-even).unowned,
    :global(.ag-row-odd).unowned {
      background-color: $unowned-background-color;
    }
  }
}

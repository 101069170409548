:local {
  .component {
    display: flex;
    flex-direction: column;

    min-height: 100%;

    > .content {
      flex-grow: 1;
    }

    > h2,
    > .footer {
      flex-shrink: 0;
    }

    > .footer {
      margin-top: 1rem;
    }
  }
}

// TODO: Try to harmonize relic banner dupes with unowned soul breaks

$dupe-background-color: #f0f0f7;

$unowned-background-color: #f0f0f0;

// Not included: super, unique
$soulBreak-colors: (
  'burst': inherit,
  'overstrike': #c00000,
  'ultra': #538135,
  'chain': #ed7d31,
  'awakening': #5b9bd5,
  'synchro': #5b60d5,
  'glint': #bf8f01,
  'arcaneDyad': #41b7c4,
  'legendMateria': #7030a0,
  'overstrikeLimitBreak': #9c007f,
  'guardianSummon': #37ad00,
);

$relic-icon-size: 64px;

@import '../shared/seriesIcons';

:local {
  /* We want the icons to come out to about 40px, to match the size of the
   * game-icons we use for world categories.  Taking into account the internal
   * padding of FFRK's series icons, this works out to 42px.
   */
  $scaled-series-icon-size: 42px;

  $icon-size: 40px;

  $icon-left-offset: 23px;

  li.completed {
  }

  li.mastered {
    text-decoration: line-through;
  }

  .details {
    font-size: small;
    margin-bottom: 0;

    span {
      display: inline-block;
      width: 8em;
    }
  }

  .title:not(.seriesIcon) {
    background: no-repeat $icon-left-offset;
    background-size: auto $icon-size;
  }

  .title.seriesIcon {
    @include seriesIconBackground($scaled-series-icon-size, $icon-left-offset);
  }

  .titleText {
    padding-left: calc(#{$icon-size} + 1rem);
  }
}

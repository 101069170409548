@import '../shared/tooltips';

:local {
  .component {
    width: 35em;
    display: flex;
    // Increase from react-tooltip's default size
    font-size: 0.9rem;

    .iconsBlock {
      margin-right: 1em;
      max-width: $tooltip-icon-width;

      > img {
        width: $tooltip-icon-width;
        height: $tooltip-icon-height;
      }
    }

    .textBlock {
      width: 100%;

      > :last-child {
        margin-bottom: 0;
      }
    }

    .schoolDetail, .castTimeDetail, .sbDetail {
      font-style: italic;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    .schoolDetail, .sbDetail {
      width: 30%;
    }
    .castTimeDetail {
      width: 40%;
    }

    .eventDetail {
      font-style: italic;
    }
  }
}

@import '../variables';

:local {
  $margin-top: 1rem;

  :global(html),
  :global(body),
  :global(#root) {
    height: 100%;
    max-height: 100%;
  }

  // Hack: Bootstrap expects its .navbar to be at the top or bottom, but we can
  // override that to appear on the left.  Use !important to supersede
  // Bootstrap's @media styles.
  .nav {
    padding-top: $margin-top !important;
    @media screen and (min-width: $side-nav-breakpoint) {
      align-items: flex-start !important;
      flex-flow: column !important;
    }

    > :global(.navbar-brand) {
      display: block;
      padding-top: 0;
    }

    > :global(.navbar-collapse) {
      align-items: flex-start;
    }

    // Nested nav / sub-nav support
    .nav {
      display: none;
    }
    :global(.nav-link.active) + .nav {
      display: block;
      font-size: 93.75%;
      margin-left: 1rem;
      padding-top: 0 !important;

      :global(.nav-link) {
        padding-top: 0;
      }
    }
  }

  .logo {
    // Select a height of 30px to match a text .navbar-brand in our current
    // Bootstrap theme.  It also happens to provide a good vertical alignment
    // with a content pane's Page's <h2>.
    width: 62px;
    height: 30px;
  }

  .component {
    height: 100%;

    > :global(.row) {
      height: 100%;

      > .content {
        margin-top: $margin-top;
        @media screen and (min-width: $side-nav-breakpoint) {
          overflow-y: auto;
          max-height: calc(100% - #{$margin-top});
        }
      }
    }
  }

  .ad {
    align-self: flex-end;
  }
}

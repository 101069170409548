$availability-size: 20px;

:local {
  img.anima {
    width: $availability-size;
    height: $availability-size;
  }

  .newSelection {
    color: #9933cc;
    display: inline-block;
    height: $availability-size;
    vertical-align: middle;
  }
}

@import './relicsAndSoulBreaks';

:local {
  td.tier {
    width: 5%;
  }

  td.name {
    width: 25%;
  }

  td.command,
  td.effects {
    width: 70%;
  }

  tr.burstCommand > td,
  tr.braveCommand > td,
  tr.synchroCommand > td,
  tr.guardianCommand > td,
  tr.trueArcane2nd > td {
    border-top: none;
  }

  td.school {
    padding-left: 1.25em;
  }
  td.command {
    padding-left: 1.25em;
  }

  tr.jp > td.name {
    font-style: italic;
  }

  tr.unique > td.tier,
  tr.unique > td.name,
  tr.super > td.tier,
  tr.super > td.name {
    font-weight: normal;
  }

  @each $name, $color in $soulBreak-colors {
    tr.#{$name} > td.tier,
    tr.#{$name} > td.name {
      font-weight: bold;
      color: $color;
    }
  }

  // Legend materia's names are longer than our MrP-inspired soul break
  // abbreviations (e.g., "LMR1" versus "U1").  Use a smaller font to
  // compensate.  Hack: We put this in its own span to make the text
  // vertically align - there's probably a better way of doing that, but I
  // don't know how.
  .legendMateriaTier {
    font-size: 80%;
  }
}

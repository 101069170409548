:local {
  .component {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    > li > img {
      margin-right: 0.25rem;
    }
  }
}
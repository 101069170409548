// From _reboot.scss, but swapping 'Segoe UI Emoji' with 'Segoe UI Symbol',
// since Emoji looks ugly on Windows for the white diamonds we use for
// OrbCostsDisplay.module.scss.
body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Symbol', 'Segoe UI Emoji',
    'Noto Color Emoji';
}

// https://stackoverflow.com/a/35353298/25507
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.ag-header-cell-sortable {
  cursor: pointer;
}

.ag-theme-balham .ag-header-cell-label {
  width: 100%;
}

.ag-theme-balham .ag-cell {
  font-variant-numeric: tabular-nums;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

// Bootstrap variant - horizontally laid out card
.card.card-horizontal {
  flex-direction: row;

  > .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: 0;
    width: inherit;
  }
}

// Fix border for a single-card accordion - Bootstrap hides the bottom border
// for the first card, which doesn't look right if the first card is also the
// last card.
.accordion > .card:first-of-type:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

// Make links on dark table headers, as used by RelicDrawBannerTable, look
// better.  A default blue link on a white background has a contrast ratio of
// 3.97; this color is the result of lightening the default blue so that it
// gives a contrast ratio of about 3.97 against a dark background.
.table .thead-dark th > a {
  color: #3d9bff;
}

// https://stackoverflow.com/a/50550069/25507
.caret-off::before,
:global(.dropup) .caret-off::before {
  display: none;
}
.caret-off::after,
:global(.dropup) .caret-off::after {
  display: none;
}

// Fix an apparent Bootstrap bug: .sr-only shouldn't get padding, even if it
// normally would as a table cell.
table td.sr-only,
table th.sr-only {
  padding: 0;
}

.simple-blockquote {
  padding-left: 0.75em;
  margin-left: 0.25em;
  border-left: solid 2px #777;
}

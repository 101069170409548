:local {
  // Normal dimensions are 576px by 160px
  $imgWidth: 432px;
  $imgHeight: 120px;

  .component {
    width: $imgWidth;
    max-width: 100%;
  }

  .component.showAsClosed {
    img.image {
      filter: grayscale(1);
      opacity: 0.8;
    }

    color: #6c757d;
  }

  img.image {
    width: $imgWidth;
    max-width: 100%;
  }

  @media (min-width: 576px) {
    // Hack: At larger screen sizes, include a width.
    // At smaller sizes, omit, so that we can size proportionally to max-width.
    img.image {
      height: $imgHeight;
    }
  }

  div.details {
    margin-top: -10px;
    margin-bottom: 10px;
    // Duplicated from <small>
    font-size: 80%;
    display: flex;
  }

  span.count {
    display: inline-block;
    flex-grow: 1;
  }

  .mythrilCost {
    display: inline-block;
    margin-left: 1em;
  }

  .desiredChance {
    display: inline-block;
    margin-left: 1em;
  }

  span.openedClosedAt {
    display: inline-block;
    flex-grow: 1;
    text-align: right;
  }
}

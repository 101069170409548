@import '../shared/abilities';

:local {
  .component {
    font-size: 0.9rem;
  }

  col.name {
    width: $ability-name-width;
  }

  col.orbCosts {
    width: $orbCosts-width;
  }

  col.effects {
    width: calc(100% - #{$ability-name-width} - #{$orbCosts-width});
  }

  // Record Board (Hero Board) cells are taller due to showing character icons,
  // so vertically align their contents.  (Normally, cells would only be taller
  // if they word wrap; vertical alignment seems less good there.
  .recordBoard td {
    vertical-align: middle;
  }
  .recordBoard td.name {
    white-space: nowrap;
  }

  tr.grouped,
  tr.grouped > td {
    border-bottom: none;
  }
  tr.grouped + tr,
  tr.grouped + tr > td {
    border-top: none;
  }

  tr.jp > td.name {
    font-style: italic;
  }
}

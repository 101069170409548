@import '../shared/tooltips';

:local {
  .component {
    max-width: 50em;
    display: flex;

    .iconsBlock {
      margin-right: 1em;
      max-width: $tooltip-icon-width;

      > img {
        width: $tooltip-icon-width;
        height: $tooltip-icon-height;
      }
    }

    .textBlock {
      > :last-child {
        margin-bottom: 0;
      }
    }

    .statusBlock {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 0.5em;
      }

      > p {
        margin-bottom: 0;
      }
    }

    .gameDescription {
    }

    .enlirDescription,
    .unlockCondition {
      font-style: italic;
    }
  }
}

@import '../shared/relicsAndSoulBreaks';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/_breakpoints';

:local {
  .component {
    table-layout: fixed;
  }

  .grouped > tbody:hover {
    background-color: #f0f0f0;
  }

  .dupe {
    background-color: $dupe-background-color;
  }

  tr.command > td {
    border-top: none;

    // Copied from Bootstrap's .table-sm, and overriding Bootstrap's default
    // .table of 0.75rem.
    padding-top: 0.3rem;
  }
  tr.command:not(.lastCommand) > td {
    padding-bottom: 0.3rem;
  }

  .relicType {
    width: 1.25em;
    height: 1.25em;
  }

  .relicEffect {
    font-style: italic;
  }

  tr.seriesGroup {
    text-align: center;
    font-style: italic;
  }

  @include media-breakpoint-up(md) {
    // Override overflow-x from .table-responsive.  Note that we don't really
    // benefit from .table-responsive due to generally poor mobile support.
    div.container {
      overflow-x: visible;

      // Don't let the drop-down menu appear under later sticky headers.  Use
      // descending z-index to accomplish this.
      // https://stackoverflow.com/a/36609688/25507
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) th.sectionHead.withPrefsMenu {
          z-index: #{10 - $i};
        }
      }
    }

    th.sectionHead {
      position: sticky;
      top: 0;
    }

    col.characterColumn {
      width: 6em;
    }
    col.relicColumn {
      width: 10em;
    }
    col.tierColumn {
      width: 3em;
    }
    col.soulBreakColumn {
      width: 12em;
    }
    col.effectsColumn {
      width: 100%;
    }
    col.probabilityColumn {
      width: 6em;
    }
    col.selectedColumn {
      width: 2em;
    }
  }

  col.statusColumn {
    width: 0;
    padding: 0;
  }
}

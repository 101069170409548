@import '../shared/elements';

:local {
  .table {
    max-width: 100%;
    overflow-x: scroll;
  }

  .table > tbody > tr > th {
    width: 12ch;
    min-width: 12ch;
  }

  .table td {
    width: 12ch;
    max-width: 12ch;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .element::before {
    content: '⬢ ';
  }

  @each $name, $color in $element-colors {
    .element:global(.#{$name})::before {
      color: $color;
    }
  }
}

@import '../shared/relicsAndSoulBreaks';

:local {
  .component > img {
    height: $relic-icon-size;
    width: $relic-icon-size;
    margin-right: 1em;
  }

  @each $name, $color in $soulBreak-colors {
    span.#{$name} {
      font-weight: bold;
      color: $color;
    }
  }
}

@import '../../variables';
@import '../shared/relicsAndSoulBreaks';

:local {
  .component {
    // Ensure that this appears on top of RelicDrawBannerTable's withPrefsMenu headers.
    z-index: 20;

    > :global(.card-body) {
      padding: 0.5rem 0.75rem;
    }

    p:global(.card-text) {
      margin-bottom: 0;
    }
  }

  .dupe {
    border: solid $table-border-color;
    border-width: $table-border-width 0;
    background: $dupe-background-color;
  }

  .want {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: $line-height-base * 3em;
  }

  .wantItem {
    width: 50%;
  }

  .wantCount {
    display: inline-block;
    width: 40%;
  }

  .discountTooltip {
    max-width: 40em;
  }

  .discountTooltip > p:last-child {
    margin-bottom: 0;
  }

  .simulatorLink {
    color: $white !important;
  }

  .simulatorLink:hover > svg {
    animation: rollingDice 3s linear infinite;
  }

  @keyframes rollingDice {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
